<template>
  <div>
    <v-row>
      <v-col
        v-for="(offer, index) in offers"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        @click="openAddEditOfferDialog(offer)"
      >
        <v-card :class="{'owner-card-bg': getUser?.id === offer.user_id && permissions.is_seller}" outlined>
          <v-col>
            <v-img
              v-if="offer.images[0]?.image"
              :src="offer.images[0]?.image"
              contain
              class="product-image"
            ></v-img>
            <v-img
              v-else
              src="@/assets/images/empty-product.png"
              contain
              class="product-image"
            ></v-img>
          </v-col>
          <v-col class="mt-2">
            <span class="product-code">{{ $t("offersCatalog.code") + ' : '}}</span>
            <span class="product-code">{{ offer.id }}</span>
          </v-col>
          <v-col class="pt-0">
            <span class="product-name">{{ offer.name }}</span>
          </v-col>
          <v-col class="pb-1">
            <span class="product-price-label">{{ $t("offersCatalog.price") + ' : ' }}</span>
            <span class="product-price">{{ offer?.prices[0]?.price }}</span>
          </v-col>
          <v-card-actions>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12">
                <v-btn
                  block
                  depressed
                  class="btn-blue-bg text-transform-none"
                  style="font-size: 16px; font-weight: 500"
                  @click="$emit('infoDialog', offer)"
                >
                  {{ $t("offersCatalog.details") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-pagination
          v-model="pagination.page"
          :length="quantityPage"
          @input="onPageChange"
        ></v-pagination>
      </v-col>
    </v-row>
    <add-edit-offer-dialog v-if="offerDialog" :visible="offerDialog" :selectedOffer="offer" @deleteOffer="deleteOffer" @offerModified="offerModified" @close="offerDialog = false" :type="offerDialogType"/>
  </div>
</template>

<script>
import offersCatalogMixin from "@/components/offersCatalog/offersCatalogMixin";
import EventBus from "@/events/EventBus";
import user from "@/mixins/user";
import offersService from "@/services/request/offers/offersService";
import notification from "@/mixins/notifications";
import loader from "@/mixins/loader";
import AddEditOfferDialog from "@/components/offersCatalog/addEditOfferDialog.vue";

export default {
  components: {AddEditOfferDialog},
  data: () => ({
    quantityPage: 0,
    loading: false,
    offers: [],
    pagination: {
      page: 1,
      itemsPerPage: 15
    },
    offerDialog: false,
    offerDialogType: 'create',
    offer: {},
  }),
  mixins: [offersCatalogMixin, user, notification, loader],
  props: {
    my_offers: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  async mounted() {
    EventBus.$on("offerCreated", async () => {
      this.setLoading(true);
      await this.getOffers(1);
      this.setLoading(false);
    });
    this.setLoading(true);
    await this.getOffers(1);
    this.setLoading(false);
  },
  methods: {
    offerModified(modifiedOffer) {
      const index = this.offers?.findIndex((item) => item.id === modifiedOffer.id);
      if (index !== -1) {
        this.$set(this.offers, index, modifiedOffer);
      }
    },
    async deleteOffer(item) {
      try {
        await offersService.deleteOffer(item.id);
        const index = this.offers.findIndex((offer) => offer.id === item.id);

        if (index !== -1) {
          this.offers.splice(index, 1);
        }
        this.offerDialog = false;
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
      } catch (e) {
        console.log(e);
      }
    },
    openAddEditOfferDialog(item) {
      if(item?.id) {
        if(this.permissions.can_edit_offer || this.getUser.id == item.user_id) {
          this.offerDialogType = 'edit';
          this.offer = item;
          this.offerDialog = true;
        }
      }
    },
    onPageChange(page) {
      this.getOffers(page);
    },
    async getOffers(page, my_offers) {
      try {
        this.loading = true;
        const params = {};
        params.page = page
        params.per_page = 15;
        if(my_offers) {
          params.my_offers = my_offers;
        }
        const data =  await offersService.getOffers(params);
        this.offers = data.data;
        this.quantityPage = data.pagination.meta.last_page;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    my_offers(newVal) {
      if (newVal) {
        this.getOffers(1, this.my_offers);
      } else {
        this.getOffers(1, this.my_offers);
      }
    }
  },
};
</script>

<style scoped>
.product-image {
  height: 200px;
  max-width: 500px;
}

.product-code {
  font-size: 12px;
  color: var(--text-color);
}

.product-name {
  font-size: 16px;
  font-weight: normal;
  color: var(--text-color);
}

.product-price-label {
  font-size: 14px;
  color: var(--text-color-secondary);
  font-weight: normal;
}

.product-price {
  font-size: 16px;
  color: var(--text-color-primary);
  font-weight: normal;
}

.product-margin {
  font-size: 14px;
  color: var(--text-color);
}

.btn-blue-bg {
  background-color: #007bff;
  color: white;
}

.v-pagination .v-btn {
  color: var(--text-color);
}

.v-theme--dark .product-code,
.v-theme--dark .product-name,
.v-theme--dark .product-price-label,
.v-theme--dark .product-price,
.v-theme--dark .product-margin {
  color: white;
}

.v-theme--dark .btn-blue-bg {
  background-color: #0056b3;
}

.owner-card-bg {
  background-color: #cccccc;

  .theme--dark & {
    background: #404040;
  }

}
</style>
