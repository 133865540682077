<template>
  <div class="mb-2">
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        absolute
        top
    ></v-progress-linear>
    <v-card outlined color="transparent">
      <v-card-title>
        <v-row
            :class="[
            'align-center',
            'custom-v-row',
          ]"
        >
          <v-col class="custom-v-col py-0" :cols="$vuetify.breakpoint.mobile ? 8 : 6">
            <v-col cols="auto">
              {{ $t("offersCatalog.offersCatalog") }}
            </v-col>
          </v-col>
          <v-col v-if="permissions.can_add_offer" class="custom-v-col py-0 justify-end"  :cols="$vuetify.breakpoint.mobile ? 4 : 6">
            <v-col cols="auto">
              <v-btn
                  @click="openAddEditOfferDialog()"
                  block
                  depressed
                  large
                  type="submit"
                  class="btn-blue-bg text-transform-none"
                  style="font-size: 16px; font-weight: 500"
              >
                <v-icon v-if="$vuetify.breakpoint.mobile"> mdi-plus </v-icon>
                <div v-else>{{ $t("offersCatalog.addOffer") }}</div>
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
            :class="[
            'align-center',
            'custom-v-row',
          ]"
        >
          <v-col class="custom-v-col py-0">
            <v-col cols="auto">
              <v-btn
                  icon
                  :color="!$vuetify.theme.dark ? 'black' : (tableView ? 'grey' : 'white')"
                  @click="changeTableView(false)"
                  :disabled="tableView == false"
              >
                <v-icon> mdi-dots-grid </v-icon>
              </v-btn>
              <v-btn
                  icon
                  :color="!$vuetify.theme.dark ? 'black' : (tableView ? 'white' : 'grey')"
                  @click="changeTableView(true)"
                  :disabled="tableView == true"
              >
                <v-icon> mdi-view-list </v-icon>
              </v-btn>
              </v-col>
            </v-col>
          <v-col class="custom-v-col justify-end py-0">
            <v-col cols="auto">
              <v-btn @click="my_offers = !my_offers" icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                <v-icon> mdi-swap-vertical </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              {{ $t("offersCatalog.by_popularity") }}
            </v-col>
            <v-btn icon :color="$vuetify.theme.dark ? 'white' : 'black'">
              <v-icon> mdi-chevron-down </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <offers-catalog-cards v-if="!tableView" :my_offers="my_offers" @infoDialog="openInfoDialog" />
    <offers-catalog-list v-if="tableView" :my_offers="my_offers" @infoDialog="openInfoDialog" />
    <add-edit-offer-dialog v-if="offerDialog" :visible="offerDialog" :selectedOffer="offer" @close="offerDialog = false" :type="offerDialogType"/>
  </div>
</template>

<script>
import OffersCatalogCards from "@/components/offersCatalog/offersCatalogCards.vue";
import OffersCatalogList from "@/components/offersCatalog/offersCatalogList.vue";
import {mapGetters} from "vuex";
import AddEditOfferDialog from "@/components/offersCatalog/addEditOfferDialog.vue";
import user from "@/mixins/user";
import store from "@/store";
import * as actionTypes from "@/store/modules/offersCatalogListValue/types/actions";
import * as getterTypes from "@/store/modules/offersCatalogListValue/types/getters";

export default {
  components: {AddEditOfferDialog, OffersCatalogList, OffersCatalogCards},
  mixins: [user],
  data: () => ({
    loading: false,
    infoDialog: false,
    offer: {},
    offerDialog: false,
    offerDialogType: 'create',
    my_offers: false
  }),
  methods: {
    openInfoDialog(item) {
      this.$router
          .push({
            name: "product-details",
            params: {
              product: item.id
            }
          })
          .catch(() => {});
    },
    openAddEditOfferDialog() {
      if (this.permissions.can_add_offer) {
        this.offerDialogType = 'create';
        this.offer = {};
        this.offerDialog = true;
      }
    },
    changeTableView(value) {
      store.dispatch(`offersCatalogListValue/${actionTypes.SET_OFFER_VALUE}`,  value);
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    ...mapGetters('offersCatalogListValue', {
      tableView: getterTypes.GET_OFFER_VALUE,
    }),
    listTable: {
      get() {
        return this.tableView;
      },
      set(listTable) {
        return listTable;
      },
    },
  }
};
</script>

<style scoped>
</style>
