var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"orange","absolute":"","top":""}}):_vm._e(),_c('v-card',{attrs:{"outlined":"","color":"transparent"}},[_c('v-card-title',[_c('v-row',{class:[
            'align-center',
            'custom-v-row',
          ]},[_c('v-col',{staticClass:"custom-v-col py-0",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 8 : 6}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("offersCatalog.offersCatalog"))+" ")])],1),(_vm.permissions.can_add_offer)?_c('v-col',{staticClass:"custom-v-col py-0 justify-end",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 4 : 6}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"btn-blue-bg text-transform-none",staticStyle:{"font-size":"16px","font-weight":"500"},attrs:{"block":"","depressed":"","large":"","type":"submit"},on:{"click":function($event){return _vm.openAddEditOfferDialog()}}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-icon',[_vm._v(" mdi-plus ")]):_c('div',[_vm._v(_vm._s(_vm.$t("offersCatalog.addOffer")))])],1)],1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',{class:[
            'align-center',
            'custom-v-row',
          ]},[_c('v-col',{staticClass:"custom-v-col py-0"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":!_vm.$vuetify.theme.dark ? 'black' : (_vm.tableView ? 'grey' : 'white'),"disabled":_vm.tableView == false},on:{"click":function($event){return _vm.changeTableView(false)}}},[_c('v-icon',[_vm._v(" mdi-dots-grid ")])],1),_c('v-btn',{attrs:{"icon":"","color":!_vm.$vuetify.theme.dark ? 'black' : (_vm.tableView ? 'white' : 'grey'),"disabled":_vm.tableView == true},on:{"click":function($event){return _vm.changeTableView(true)}}},[_c('v-icon',[_vm._v(" mdi-view-list ")])],1)],1)],1),_c('v-col',{staticClass:"custom-v-col justify-end py-0"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){_vm.my_offers = !_vm.my_offers}}},[_c('v-icon',[_vm._v(" mdi-swap-vertical ")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("offersCatalog.by_popularity"))+" ")]),_c('v-btn',{attrs:{"icon":"","color":_vm.$vuetify.theme.dark ? 'white' : 'black'}},[_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1)],1)],1)],1),(!_vm.tableView)?_c('offers-catalog-cards',{attrs:{"my_offers":_vm.my_offers},on:{"infoDialog":_vm.openInfoDialog}}):_vm._e(),(_vm.tableView)?_c('offers-catalog-list',{attrs:{"my_offers":_vm.my_offers},on:{"infoDialog":_vm.openInfoDialog}}):_vm._e(),(_vm.offerDialog)?_c('add-edit-offer-dialog',{attrs:{"visible":_vm.offerDialog,"selectedOffer":_vm.offer,"type":_vm.offerDialogType},on:{"close":function($event){_vm.offerDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }