<template>
  <OffersCatalogComponent />
</template>


<script>

import OffersCatalogComponent from "@/components/offersCatalog/offersCatalogComponent.vue";

export default {
  name: "OffersCatalogView",
  components: {
    OffersCatalogComponent,
  },
};
</script>