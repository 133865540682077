<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="offers"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        :items-per-page="offers.length"
        :height="getTableHeight"
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.id" :class="{'owner-card-bg': getUser?.id === item.user_id && permissions.is_seller}" @click="openAddEditOfferDialog(item)">
          <td>
            <v-col>
              <v-img
                  v-if="item.images[0]?.image"
                  :src="item.images[0]?.image"
                  contain
                  class="product-image"
              ></v-img>
              <v-img
                  v-else
                  src="@/assets/images/empty-product.png"
                  contain
                  class="product-image"
              ></v-img>
            </v-col>
          </td>
          <td>
            <span class="product-code">{{ $t("offersCatalog.code") + ' : '}}</span>
            <span class="product-code">{{ item.id }}</span>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            <span class="product-price-label">{{ $t("offersCatalog.price") + ' : ' }}</span>
            <span class="product-price">{{ item?.prices[0]?.price }}</span>
          </td>
          <td>
            <v-btn
                block
                depressed
                class="btn-blue-bg text-transform-none"
                style="font-size: 16px; font-weight: 500"
                @click="$emit('infoDialog', item)"
            >
            {{ $t("offersCatalog.details") }}
            </v-btn>
          </td>
        </tr>
        <tr>
          <td :colspan="12">
            <v-progress-linear
                v-if="isLoadingItems"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <div
                v-observer="{ nextPage: nextPage }"
                class="observer"
                v-if="!loadMoreDisabled && !isLoadingItems"
            ></div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
    <add-edit-offer-dialog v-if="offerDialog" :visible="offerDialog" :selectedOffer="offer" @deleteOffer="deleteOffer" @offerModified="offerModified" @close="offerDialog = false" :type="offerDialogType"/>
  </div>
</template>

<script>
import offersCatalogMixin from "@/components/offersCatalog/offersCatalogMixin";
import EventBus from "@/events/EventBus";
import offersService from "@/services/request/offers/offersService";
import dataTableMixin from "@/mixins/dataTableMixin";
import loader from "@/mixins/loader";
import user from "@/mixins/user";
import AddEditOfferDialog from "@/components/offersCatalog/addEditOfferDialog.vue";

export default {
  components: {AddEditOfferDialog},
  data: () => ({
    page: 1,
    quantityPage: 0,
    isLoadingItems: false,
    loadMoreDisabled: false,
    offsetBottom: 305,
    currentPage: 1,
    offers: [],
    offerDialog: false,
    offerDialogType: 'create',
    offer: {},
  }),
  props: {
    my_offers: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('form.image'), value: 'image', sortable: false },
        { text: this.$t('form.code'), value: 'id', sortable: false },
        { text: this.$t('settings.name'), value: 'name', sortable: false },
        { text: this.$t('settings.price'), value: 'price', sortable: false },
        { text: "", value: 'actions', sortable: false }
      ];
    }
  },
  mixins: [offersCatalogMixin, dataTableMixin, loader, user],
  async mounted() {
    EventBus.$on("offerCreated", async () => {
      this.setLoading(true);
      await this.getOffers(1);
      this.setLoading(false);
    });
    this.setLoading(true);
    await this.getOffers(this.page);
    this.setLoading(false);
  },
  methods: {
    offerModified(modifiedOffer) {
      console.log(modifiedOffer)
      const index = this.offers?.findIndex((item) => item.id === modifiedOffer.id);
      if (index !== -1) {
        this.$set(this.offers, index, modifiedOffer);
      }
    },
    async deleteOffer(item) {
      try {
        await offersService.deleteOffer(item.id);
        const index = this.offers.findIndex((offer) => offer.id === item.id);

        if (index !== -1) {
          this.offers.splice(index, 1);
        }
        this.offerDialog = false;
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
      } catch (e) {
        console.log(e);
      }
    },
    openAddEditOfferDialog(item) {
      if(item?.id) {
        if(this.permissions.can_edit_offer || this.getUser.id == item.user_id) {
          this.offerDialogType = 'edit';
          this.offer = item;
          this.offerDialog = true;
        }
      }
    },
    async getOffers(page, my_offers) {
      try {
        this.isLoadingItems = true;
        const params = {};
        params.page = page
        params.per_page = this.perPage;
        if(my_offers) {
          params.my_offers = my_offers;
        }
        const data =  await offersService.getOffers(params);
        if (data.data && data.data.length > 0) {
          if (page > 1) {
            this.offers = [...this.offers, ...data.data];
          } else {
            this.offers = data.data;
          }
        }
        this.quantityPage = data.pagination.meta.last_page;
        this.loadMoreDisabled = data.data.length < this.perPage;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingItems = false;
      }
    },
    async nextPage() {
      this.currentPage += 1;
      this.isLoadingItems = true;
      await this.getOffers(this.currentPage);
      this.isLoadingItems = false;
    },
  },
  watch: {
    my_offers(newVal) {
      if (newVal) {
        this.getOffers(1, this.my_offers);
      } else {
        this.getOffers(1, this.my_offers);
      }
    }
  },
};
</script>

<style scoped>
.product-image {
  height: 70px;
  max-width: 150px;
}

.owner-card-bg {
  background-color: #cccccc;

  .theme--dark & {
    background: #404040;
  }

}
</style>
